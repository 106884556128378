<template>
  <footer
    component-name="txt-moodboard-date"
    class="flex"
    :class="{
      'flex-col': wrap,
      'flex-row items-baseline gap-lg': !wrap,
    }"
  >
    <wrapper-reveal reveal="fade-from-right">
      <div
        class="whitespace-nowrap"
        :class="{
          'text-overline-small text-skin-muted': wrap,
          'text-body text-skin-base': !wrap,
        }"
        v-html="
          `${total} ${
            total === 1 ? $t.labels.item_singular : $t.labels.item_plural
          }`
        "
      ></div>
    </wrapper-reveal>

    <wrapper-reveal
      reveal="fade-from-right"
      class="flex items-baseline gap-2xs"
      :class="{
        'text-body-small text-skin-muted': wrap,
        'text-body text-skin-base': !wrap,
      }"
    >
      <div
        v-html="
          updated && updated > created
            ? $t.labels.updated_on
            : $t.labels.created_on
        "
      ></div>

      <txt-geodate :unix="updated && updated > created ? updated : created" />
    </wrapper-reveal>
  </footer>
</template>

<script setup>
const props = defineProps({
  total: {
    type: Number,
    required: true,
  },
  created: {
    type: Number,
    required: true,
  },
  updated: {
    type: Number,
    required: true,
  },
  wrap: {
    type: Boolean,
    required: false,
  },
});
</script>
